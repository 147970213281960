import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '&.MuiSwitch-root': {
    width: '2.25rem',
    height: '1.125rem',
    padding: 0,
    display: 'flex',
  },
  '& .MuiSwitch-switchBase': {
    padding: '0.125rem',
    '&.Mui-checked': {
      transform: 'translateX(1.125rem)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.background.saveGreen,
      },
    },
    '&.Mui-disabled ': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        background: theme.palette.background.shuttleGray,
      },
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: '1rem',
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
}));

export { StyledSwitch };
