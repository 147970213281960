import React from 'react';
import PropTypes from 'prop-types';
import { Slide, Snackbar, styled, Typography, Box, alpha } from '@mui/material';
import { TOAST_TYPE, MESSAGE_STRINGS } from './constants';
import ErrorIcon from '../../assets/img/smallErrorIcon.svg';
import WarningIcon from '../../assets/img/warningIcon.svg';
import SuccessIcon from '../../assets/img/successIcon.svg';
import InfoIcon from '../../assets/img/infoIcon.svg';

const bgColors = {
  [TOAST_TYPE.ERROR]: 'cocoaToneRed',
  [TOAST_TYPE.WARNING]: 'bronzeTone',
  [TOAST_TYPE.SUCCESS]: 'blackOliveGreen',
  [TOAST_TYPE.INFO]: 'nileBlue',
};

const borderColors = {
  [TOAST_TYPE.ERROR]: 'lavaRed',
  [TOAST_TYPE.WARNING]: 'tangerineColor',
  [TOAST_TYPE.SUCCESS]: 'saveGreen',
  [TOAST_TYPE.INFO]: 'highlightBlue',
};
const toastIcons = {
  [TOAST_TYPE.ERROR]: () => <ErrorIcon height={32} width={32} />,
  [TOAST_TYPE.WARNING]: () => <WarningIcon height={32} width={32} />,
  [TOAST_TYPE.SUCCESS]: () => <SuccessIcon height={32} width={32} />,
  [TOAST_TYPE.INFO]: () => <InfoIcon height={32} width={32} />,
};

const SnackBarContent = ({ type = 'SUCCESS', message = '' }) => {
  const Icon = toastIcons[type] || toastIcons[TOAST_TYPE.SUCCESS];
  const fallBackMessage = MESSAGE_STRINGS[`Toast.message.${type}`];
  return (
    <Box display="flex" alignItems="center" minHeight={64} marginX="1rem">
      <Icon />
      <Box marginLeft="1rem">
        <Typography variant="subtitle1" data-testid="toast-content">
          {message || fallBackMessage}
        </Typography>
      </Box>
    </Box>
  );
};

SnackBarContent.defaultProps = {
  type: 'SUCCESS',
  message: '',
};

SnackBarContent.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

const CustomizedToast = styled(Snackbar, {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ theme, type }) => {
  const bgColor = bgColors[type] || bgColors[TOAST_TYPE.SUCCESS];
  const borderColor = borderColors[type] || borderColors[TOAST_TYPE.SUCCESS];
  return {
    zIndex: theme.zIndex.modal + 1,
    '.MuiSnackbarContent-root': {
      minWidth: '28.5rem',
      background: alpha(theme.palette.background[bgColor], 0.95),
      border: `0.0625rem solid ${theme.palette.border[borderColor]}`,
      borderRadius: '0.25rem',
      padding: 0,
    },
    '.MuiSnackbarContent-message': { width: '100%', padding: 0 },
  };
});

const SlideTransitionComponent = ({ direction, children, ...props }) => (
  <Slide {...props} direction={direction}>
    {children}
  </Slide>
);

SlideTransitionComponent.defaultProps = {
  children: null,
  direction: 'left',
};
SlideTransitionComponent.propTypes = {
  direction: PropTypes.string,
  children: PropTypes.node,
};

const Toast = ({
  open = false,
  type = 'SUCCESS',
  message = MESSAGE_STRINGS['Toast.message.SUCCESS'],
  vertical = 'bottom',
  horizontal = 'center',
  direction = 'right',
  ...others
}) => (
  <CustomizedToast
    type={type}
    anchorOrigin={{ vertical, horizontal }}
    open={open}
    TransitionComponent={(props) =>
      SlideTransitionComponent({ ...props, direction })
    }
    data-testid={`toast-${type}`}
    message={<SnackBarContent message={message} type={type} />}
    {...others}
  />
);

Toast.defaultProps = {
  open: false,
  type: 'SUCCESS',
  message: MESSAGE_STRINGS['Toast.message.SUCCESS'],
  vertical: 'bottom',
  horizontal: 'center',
  direction: 'right',
};

Toast.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  message: PropTypes.string,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  direction: PropTypes.string,
  children: PropTypes.node,
};
export default Toast;
