import { styled } from '@mui/material';

export const ConfigRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isErrorState',
})(({ theme, isErrorState }) => ({
  fontSize: '0.875rem',
  padding: '0 1.25rem',
  height: isErrorState ? '6rem' : '3rem',
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.customColors.wrapperGrey}`,
  borderBottom: `1px solid ${theme.customColors.wrapperGrey}`,
}));

export const Description = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const SaveButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '3.5rem',
  padding: '0.5rem',
}));

export const OuterFlexContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const InnerFlexContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
}));

export const SwitchContainer = styled('div')(() => ({
  padding: '1rem',
}));
