import { StrictMode } from 'react';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  SplitClient,
  SplitFactory,
  SplitTreatments,
} from '@splitsoftware/splitio-react';
import { navigateToUrl } from 'single-spa';
import {
  globalstate$,
  theme,
  FEATURE_FLAGS,
  splitRootFactory,
} from '@smf/ui-util-app';
import ConfigContainer from './containers/ConfigContainer';
import { ToastProvider } from './context/toastContext';
import CommonBackdrop from './components/CommonBackdrop';
import LoadingIndicator from './components/LoadingIndicator';

export default function Root() {
  const queryClient = new QueryClient();

  const renderPosConfig = (treatments) => {
    const { treatment } = treatments;
    if (treatment === 'off') {
      navigateToUrl('/');
      return <>Module Unavailable</>;
    }
    return <ConfigContainer />;
  };

  return (
    <StrictMode>
      <SplitFactory factory={splitRootFactory}>
        <SplitClient
          splitKey={globalstate$._value?.userData?.email?.toLowerCase()}
        >
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <ToastProvider>
                <SplitTreatments names={[FEATURE_FLAGS.POS]}>
                  {({ treatments, isReady }) => {
                    return isReady ? (
                      renderPosConfig(treatments[FEATURE_FLAGS.POS])
                    ) : (
                      <CommonBackdrop open>
                        <LoadingIndicator />
                      </CommonBackdrop>
                    );
                  }}
                </SplitTreatments>
              </ToastProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </SplitClient>
      </SplitFactory>
    </StrictMode>
  );
}
