// App Titles
export const PAGE_TITLE_UI_SETTINGS = 'Production Order Sequencing';
export const ACCORDIAN_TITLES = {
  UI_SETTINGS: 'UI Settings',
};

// Accordian Labels
export const ACCORDIAN_LABELS = {
  ENABLE_POS: 'Enable Production Order Sequencing',
};

// Button Labels
export const BUTTON_TEXT = {
  SAVE: 'Save',
};

// Button Constants
export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
export const BUTTON_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small',
};

// Toast Constants
export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};
