import React from 'react';
import { Box, Backdrop, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const CustomBox = styled(Box)(({ theme }) => ({
  color: theme.palette.background.default,
  textAlign: 'center',
}));

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
}));

export default function LoadingIndicator({ size }) {
  return (
    <>
      <CustomBackdrop invisible open />
      <CustomBox>
        <CircularProgress
          color="inherit"
          size={size}
          data-testid="smf-loader"
        />
      </CustomBox>
    </>
  );
}

LoadingIndicator.propTypes = {
  size: PropTypes.number,
};
